import { graphql, useStaticQuery } from "gatsby"
import { MT_COMPANY, MT_OTHER, MT_SERVICE } from "./constants"

export function FormatDate(date) {
  const options = { year: "numeric", month: "long", day: "numeric" }
  return new Date(date).toLocaleDateString("ru-RU", options)
}

export function FormatTime(time) {
  if (typeof time !== "undefined") {
    return time.replace(/:\d{2}$/, "")
  }
}

export function FormatDateTime(date) {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }
  return new Date(date).toLocaleDateString("ru-RU", options)
}

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            baseURL
          }
        }
      }
    `
  )
  return site.siteMetadata
}

export function GetMenuItems(menuItems, menuType, slug = undefined) {
  let filteredItems = []

  switch (menuType) {
    case MT_COMPANY:
      filteredItems = menuItems.companies
      break
    case MT_SERVICE:
      filteredItems = menuItems.services
      break
    case MT_OTHER:
      filteredItems = menuItems.others
      break
    default:
      filteredItems = []
  }

  //console.log('menuType', menuType, 'filteredItems', filteredItems);

  if (slug !== undefined)
    filteredItems = filteredItems.filter(item => item.slug === slug)

  return filteredItems
}

export function GetMenuTypeLabel(menuType, lang = "ru") {
  switch (menuType) {
    case MT_COMPANY:
      return "Компания"
    case MT_SERVICE:
      return "Услуги"
    case MT_OTHER:
      return "Прочее"
    default:
      return ""
  }
}

export function GetMenuTypePath(menuType, lang = "ru") {
  switch (menuType) {
    case MT_COMPANY:
      return "company"
    case MT_SERVICE:
      return "service"
    case MT_OTHER:
      return "other"
    default:
      return ""
  }
}

export function GetHeaderAuthorization() {
  if (
    typeof window !== "undefined" &&
    window.localStorage !== null &&
    window.localStorage !== undefined
  ) {
    return window.localStorage.getItem("access_token")
      ? "JWT " + window.localStorage.getItem("access_token")
      : window.sessionStorage.getItem("access_token")
      ? "JWT " + window.sessionStorage.getItem("access_token")
      : null
  } else {
    return null
  }
}

export function GetLocalStorageItem(ItemName) {
  if (
    typeof window !== "undefined" &&
    window.localStorage !== null &&
    window.localStorage !== undefined
  ) {
    return window.localStorage.getItem(ItemName)
      ? window.localStorage.getItem(ItemName)
      : null
  } else {
    return null
  }
}

export function GetSessionStorageItem(ItemName) {
  if (
    typeof window !== "undefined" &&
    window.sessionStorage !== null &&
    window.sessionStorage !== undefined
  ) {
    return window.sessionStorage.getItem(ItemName)
      ? window.sessionStorage.getItem(ItemName)
      : null
  } else {
    return null
  }
}

export function SetLocalStorageItem(ItemName, ItemValue) {
  if (
    typeof window !== "undefined" &&
    window.localStorage !== null &&
    window.localStorage !== undefined
  ) {
    window.localStorage.setItem(ItemName, ItemValue)
  }
}

export function SetSessionStorageItem(ItemName, ItemValue) {
  if (
    typeof window !== "undefined" &&
    window.sessionStorage !== null &&
    window.sessionStorage !== undefined
  ) {
    window.sessionStorage.setItem(ItemName, ItemValue)
  }
}

export function RemoveLocalStorageItem(ItemName) {
  if (
    typeof window !== "undefined" &&
    window.localStorage !== null &&
    window.localStorage !== undefined
  ) {
    if (ItemName === "everything") {
      return window.localStorage.clear()
    }
    window.localStorage.removeItem(ItemName)
  }
}

export function RemoveSessionStorageItem(ItemName) {
  if (
    typeof window !== "undefined" &&
    window.sessionStorage !== null &&
    window.sessionStorage !== undefined
  ) {
    if (ItemName === "everything") {
      return window.sessionStorage.clear()
    }
    window.sessionStorage.removeItem(ItemName)
  }
}
