import React from "react"
import { AiOutlineArrowUp } from "react-icons/ai"
import "./scroller.css"

export default function Scroller() {
  const scrollY = typeof window !== "undefined" ? window.scrollY : null
  const [scroll, setScroll] = React.useState(scrollY)

  function handleScroll() {
    setScroll(window.scrollY)
  }

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div>
      {scroll > 120 ? (
        <div className="scroller" onClick={scrollToTop}
             onKeyDown={() => {}} aria-hidden="true">
          <AiOutlineArrowUp className="scroller-icon" />
        </div>
      ) : null}
    </div>
  )
}
