import React, { useEffect } from "react"
import "./loader.css"

export default function Loader({ show = true }) {
  

  useEffect(() => {
    const loader = document.querySelector(".loader")
    const showLoader = () => loader.classList.remove("loader--hide")
    const hideLoader = () => loader.classList.add("loader--hide")
    if (show) {
      showLoader()
    } else {
      hideLoader()
    }
  }, [show])

  return <div className="loader"></div>
}
