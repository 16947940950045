import React from "react";
import { Link } from "gatsby";
// import { globalHistory } from '@reach/router'

export default function MenuLink({ href, label, className="", linkClass, onClick, children, hasChildren=false, isActive }) {
  // const path = globalHistory.location.pathname
  return (
    <li className={(hasChildren ? "down" : "") +  " " + className}>
      <Link to={href} onClick={onClick} className={linkClass} activeClassName="active">
        <span>{label}</span>
      </Link>
      {hasChildren &&
      <ul className="">
        {children}
      </ul>}
    </li>
  );
}