import React, { useContext } from "react"
import { Link } from "gatsby"
import SiteContext from "../../context/site-context"
import axiosInstance from "../../lib/axiosApi"
import logout from "../users/logout"
import { BiUser } from "react-icons/bi"
import "./header.css"
import NavBar from "./nav-bar"

export default function Header() {
  const {
    loadingState,
    userState,
    userSetState,
    showLoginSetState,
    loggedOutSetState,
  } = useContext(SiteContext)

  return (
    <div className="header-wrapper">
      <div className="container">
        <div className="header relative">
          {loadingState.menuItemsLoaded === true &&
          <>
            {userState.username === "" || userState.username === null ? (
               <a
                  onClick={e => {
                    e.preventDefault()
                    showLoginSetState(true)
                  }}
                  className="header-callback-btn fancyboxmodal hidden-xs"
                  style={{cursor: "pointer"}}
               >
                 <i className="material-icons">apps</i> Личный кабинет
               </a>
            ) : (
               <div className="header-dropdown logged-in hidden-xs">
                 <div className="header-dropdown-content">
                   <Link to="/cabinet/">Личный кабинет</Link>
                   <Link to="/cabinet/profile">Профиль</Link>
                   <Link to="/cabinet/tariff">Мой тариф</Link>
                   <Link to="/cabinet/bookmarked-events">Мои закладки</Link>
                   <Link to="/cabinet/registered-events">Мои мероприятия</Link>
                   <a
                      onClick={() =>
                         logout(axiosInstance, userSetState, loggedOutSetState)
                      }
                      style={{cursor: "pointer"}}
                   >
                     Выйти
                   </a>
                 </div>
                 <a
                    className="header-callback-btn logged-in fancyboxmodal hidden-xs"
                    style={{cursor: "pointer"}}
                 >
                   <BiUser className="user-logo"/>
                   <span
                      dangerouslySetInnerHTML={{
                        __html: userState.fullName
                           ? userState.fullName
                           : userState.username,
                      }}
                   />
                 </a>
               </div>
            )
            }
          </>
          }

          <div className="logo">
            <div className="table">
              <div className="table-cell">
                <Link to="/">
                  <img
                    src="/images/site/logo.png"
                    alt="логотип e-sandyq.kz - портал информационно-технической поддержки"
                  />
                </Link>
              </div>
            </div>
          </div>

            <NavBar />
            {/* <div className="table">
              <div className="table-cell">
                <div className="row min">
                  <div className="col-md-6 col-lg-4 visible-md visible-lg">
                    <div className="element el1 relative">
                      <i className="material-icons">place</i>
                      г. Нур-Султан, ул. Петрова 18/2
                    </div>
                  </div>

                  <div className="col-lg-4 visible-lg">
                    <div className="element el2 relative">
                      <i className="material-icons">access_time</i>
                      Режим работы: с 9.00 - 18.30 без выходных
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4">
                    <div className="element el3 relative">
                      <div className="header-tel">
                        <i className="material-icons">phone</i>
                        <a href="tel:87783155223">+7 (705) 956-11-05</a>
                      </div>

                      <div className="header-email">
                        <i className="material-icons">email</i>
                        <a href="mailto:info@qfin.kz">info@qfin.kz</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        </div>
      </div>
    </div>
  )
}
