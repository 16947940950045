import React from "react"
import SiteContext from "./site-context"


const SiteProvider = ({ children }) => {

  const [loadingState, loadingSetState] = React.useState(() => {
    return {
      menuItemsLoaded: null,
      mainSliderLoaded: null,
      newsLoaded: null,
      mainPageLoaded: null,
      achievementsLoaded: null,
      reviewsLoaded: null
    }
  })

  const [paginatorState, paginatorSetState] = React.useState(() => {
    return {
      pagesCount: 0,
      currentPage: 0,
      pageUrl: ""
    }
  })

  const [userState, userSetState] = React.useState(() => {
    return { username: "", id: null, fullName: "", tariffId: [], photo: "" , "iin": ""}
  })

  const [menuItemsState, menuItemsSetState] = React.useState(() => {
    return {loadedTime: null, companies: [], services: [], tools: [], others: []}
  })

  const [partnersState, partnersSetState] = React.useState(() => {
    return []
  })

  const [achievementsState, achievementsSetState] = React.useState(() => {
    return []
  })

  const [showLoginState, showLoginSetState] = React.useState(() => false)

  const [loginRedirect, setLoginRedirect] = React.useState(() => {
    return {redirect: true, path: ""}
  })

  const [selectedItemTariff, setSelectedItemTariff] = React.useState(() => [])

  const [loggedOutState, loggedOutSetState] = React.useState(() => false)

  const [showAlert, setShowAlert] = React.useState(() => false)

  const [showLoader, setShowLoader] = React.useState(() => true)

  const [currentCategory, setCurrentCategory] = React.useState(() => 1)


  const State = {
    loadingState, loadingSetState,
    paginatorState, paginatorSetState,
    userState, userSetState,
    menuItemsState, menuItemsSetState,
    partnersState, partnersSetState,
    achievementsState, achievementsSetState,
    showLoginState, showLoginSetState,
    loggedOutState, loggedOutSetState,
    loginRedirect, setLoginRedirect,
    showAlert, setShowAlert,
    showLoader, setShowLoader,
    selectedItemTariff, setSelectedItemTariff,
    currentCategory, setCurrentCategory
  }

  return (
    <SiteContext.Provider value={ State }>
      { children }
    </SiteContext.Provider>
  )
}

export default SiteProvider
