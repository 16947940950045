import axiosInstance from "./axiosApi"

export async function AxiosInstanceGet(url, functionName = "runAxiosInstance") {
    return await axiosInstance.get(url).catch(error => {
        console.error('ON ' + functionName, error)
        return null
    })
}

export async function AxiosInstanceDelete(url, functionName = "runAxiosInstance") {
    return await axiosInstance.delete(url).catch(error => {
        console.error('ON ' + functionName, error)
    })
}

export async function AxiosInstancePost(url, data, functionName = "runAxiosInstance") {
    return await axiosInstance.post(url, data).catch(error => {
        console.error('ON ' + functionName, error)
    })
}

export async function AxiosInstancePut(url, data, functionName = "runAxiosInstance") {
    return await axiosInstance.put(url, data).catch(error => {
        console.error('ON ' + functionName, error);
    });
}


export async function getMenus(menuType = null) {
    const url = "/menus/" + (menuType === null ? "" : "?menuType=" + menuType)
    return AxiosInstanceGet(url, "getMenus")
}

export async function getFooterMenus() {
    return await AxiosInstanceGet("/footer-menus/", "getFooterMenus")
}

export async function getNews(page = 1) {
    return await AxiosInstanceGet("/news/?page=" + page, "getNews")
}

export async function getPaidNews(page = 1) {
    return await AxiosInstanceGet("/paid-news/?page=" + page, "getPaidNews")
}

export async function getNewsById(pageType, id) {
    return await AxiosInstanceGet("/" + pageType + "/" + id + "/", "getNewsById")
}

export async function getArticles(page = 1) {
    return await AxiosInstanceGet("/articles/?page=" + page, "getArticles")
}

export async function getEvents(pageType, page = 1, category = 0) {    
    if (pageType === "meetings") {
        return await AxiosInstanceGet("/bbb-meetings-list")
    }
    else {
        // console.log('category', category)
        return await AxiosInstanceGet("/" + pageType + "/?page=" + page 
        + (!category ? "" : "&category="+category), "getEvents")
    }
}

export async function getEventById(pageType, id) {
    return await AxiosInstanceGet("/" + pageType + "/" + id, "getEventById")
}

export async function getClosestWebinar() {
    return await AxiosInstanceGet("/closest-webinar/", "getClosestWebinar")
}

export async function getClosestMasterclass() {
    return await AxiosInstanceGet("/closest-master-class/", "getClosestMasterclass")
}

export async function getClosestCourse() {
    return await AxiosInstanceGet("/closest-course/", "getClosestCourse")
}

export async function getRegisteredWebinars() {
    return await AxiosInstanceGet("/webinar-registrations/", "getRegisteredWebinars")
}

export async function getRegisteredMasterclasses() {
    return await AxiosInstanceGet("/master-class-registrations/", "getRegisteredMasterclasses")
}

export async function getRegisteredCourses() {
    return AxiosInstanceGet("/course-registrations/", "getRegisteredCourses")
}

export async function getBookmarkedArticles() {
    return AxiosInstanceGet("/article-links/", "getBookmarkedArticles")
}

export async function getBookmarkedPaidNews() {
    return AxiosInstanceGet("/paid-news-links/", "getBookmarkedPaidNews")
}

export async function getBookmarkedWebinars() {
    return await AxiosInstanceGet("/webinar-links/", "getBookmarkedWebinars")
}

export async function getBookmarkedMasterclasses() {
    return await AxiosInstanceGet("/masterclass-links/", "getBookmarkedMasterclasses")
}

export async function getBookmarkedCourses() {
    return await AxiosInstanceGet("/course-links/", "getBookmarkedCourses")
}

export async function getReviews(page = 1) {
    return await AxiosInstanceGet("/feedbacks/?page=" + page, "getReviews")
}

export async function getInfoPage(menu_id) {
    return await AxiosInstanceGet("/infopages/?menu_id=" + menu_id, "getInfoPage")
}

export async function getPartners() {
    return await AxiosInstanceGet("/partners/", "getPartners")
}

export async function getAchievements() {
    return await AxiosInstanceGet("/achievements/", "getAchievements")
}

export async function getFiles() {
    return await AxiosInstanceGet("/files/", "getFiles")
}

export async function getMainSliders() {
    return await AxiosInstanceGet("/main-sliders/", "getMainSliders")
}

export async function getInfoSystems() {
    return await AxiosInstanceGet("/references/", "getInfoSystems")
}

export async function getTariffsList() {
    return await AxiosInstanceGet("/tarifs/", "getTariffsList")
}

export async function getPositionsList() {
    return await AxiosInstanceGet("/positions/", "getPositionsList")
}

export async function registerToEvent(pageType, id) {
    return await AxiosInstancePost("/" + pageType + "-registrations/?id=" + id, null, "registerToEvent")
}

export async function checkRegistration(pageType, id) {
    return await AxiosInstanceGet(
        "/check-" + pageType + "-registration/?id=" + id, "checkRegistration"
    )
}

export async function deleteRegistration(pageType, id) {
    return await AxiosInstanceDelete(
        "/delete-" + pageType + "-registration/?id=" + id, "deleteRegistration"
    )
}

export async function addBookmark(pageType, id) {
    return await AxiosInstancePost("/add-" + pageType + "-link/?id=" + id, null, "addBookmark")
}

export async function deleteBookmark(pageType, id) {
    return await AxiosInstanceDelete("/delete-" + pageType + "-link/?id=" + id, "deleteBookmark")
}

export async function checkBookmark(pageType, id) {
    return await AxiosInstanceGet("/check-" + pageType + "-link/?id=" + id, "checkBookmark")
}

export async function getUserDetails() {
    return await AxiosInstanceGet("/user-detail/", "getUserDetails")
}

export async function getVacancies() {
    return await AxiosInstanceGet("/vacancies/", "getVacancies")
}

export async function getEmployees() {
    return await AxiosInstanceGet("/district-employees/", "getEmployees")
}

export async function getQuestions(page = 1) {
    return await AxiosInstanceGet("/questions/?page=" + page, "getQuestions")
}

export async function getQuestionById(id) {
    return await AxiosInstanceGet("/get-question/" + id, "getQuestionById")
}

export async function postQuestion(data) {
    return await AxiosInstancePost("/question-post/", data, "postQuestion")
}

export async function getBookmarkedQuestions() {
    return await AxiosInstanceGet("/question-links/", "getBookmarkedQuestions")
}

export async function getContractors() {
    return await AxiosInstanceGet("/contractors/", "getContractors")
}

export async function getTarifContractList() {
    return await AxiosInstanceGet("/contracts-tarif/", "getTarifContractList")
}

export async function getComments(pageType, id) {
    return await AxiosInstanceGet("/" + pageType + "-comments/" + id, "getComments")
}

export async function postComment(pageType, data) {
    return await AxiosInstancePost("/" + pageType + "-comment-post/", data, "postComment")
}

export async function search(query) {
    return await AxiosInstanceGet("/website-search?search=" + query)
}

export async function verifyPasswordResetToken(token) {
    return await AxiosInstancePost("/password_reset/validate_token/", {
        token: token,
    })
}

export async function passwordReset(token, password) {
    return await AxiosInstancePost("/password_reset/confirm/", {
        token: token,
        password: password,
    })
}

export async function getHolidays() {
    return await AxiosInstanceGet("/holidays")
}

export async function calculateWorkingDays(weekdays, data) {
    return await AxiosInstancePost("/holidays/" + weekdays, data)
}

export async function getPayrollTypes() {
    return await AxiosInstanceGet("/payroll-types")
}

export async function getSalarySpheres() {
    return await AxiosInstanceGet("/salary/spheres")
}

export async function getSalaryElements() {
    return await AxiosInstanceGet("/salary/elements")
}

export async function getSalaryDegrees() {
    return await AxiosInstanceGet("/salary/degrees")
}

export async function getSalaryExperiences() {
    return await AxiosInstanceGet("/salary/experiences")
}

export async function calculateSalary(calculation_type, data) {
    return await AxiosInstancePost("/salary/zarplata/" + calculation_type, data)
}

export async function getAccountTo1C(data) {
    return await AxiosInstancePost("/account-to-1c/", data)
}

export async function getSubdivision(contractorId) {
    return await AxiosInstanceGet(`/tarification/subdivision/?contractor_id=${contractorId}`)
}

export async function getHiringPosition(contractorId) {
    return await AxiosInstanceGet(`/tarification/hirring-position/?contractor_id=${contractorId}`)
}
export async function getHiringPositionByHour(contractorId) {
    return await AxiosInstanceGet(`/tarification/hirring-position-byhour/?contractor_id=${contractorId}`)
}

export async function getHiringPositionDetails(id) {
    return await AxiosInstanceGet(`/tarification/hirring-positions/${id}/`)
}
export async function getHiringPositionByHourDetails(id) {
    return await AxiosInstanceGet(`/tarification/hirring-positions-byhour/${id}/`)
}

export async function getWorker(workerId) {
    return await AxiosInstanceGet(`/tarification/workers/?worker_id=${workerId}`)
}

export async function getWorkerSubdivision(subdivisionId) {
    return await AxiosInstanceGet(`/tarification/workers-subdivision/?subdivision_id=${subdivisionId}`)
}

export async function getTarifWorkers(contractorId) {
    return await AxiosInstanceGet(`/tarification/workers-contractor/?contractor_id=${contractorId}`)
}

export async function createSubdivision(data) {
    return await AxiosInstancePost("/tarification/subdivision/create/", data)
}

export async function createWorker(data) {
    return await AxiosInstancePost("/tarification/workers/create/", data)
}
export async function createHiringPosition(data) {
    return await AxiosInstancePost("/tarification/hirring-position/create/", data)
}

export async function createHiringPositionByHour(data) {
    return await AxiosInstancePost("/tarification/hirring-position-byhour/create/", data)
}

export async function updateSubdivision(subdivisionId, data) {
    const url = `/tarification/subdivision/update/${subdivisionId}/`;
    return await AxiosInstancePut(url, data);
}

export async function updateHiringPosition(id, data) {
    const url = `tarification/hiring-positions/update/${id}/`;
    return await AxiosInstancePut(url, data);
}
export async function updateHiringPositionByHour(id, data) {
    const url = `tarification/hiring-positions-byhour/update/${id}/`;
    return await AxiosInstancePut(url, data);
}

export async function updateWorker(id, data) {
    const url = `tarification/workers/update/${id}/`;
    return await AxiosInstancePut(url, data);
}

export async function deleteSubdivision(subdivisionId) {
    const url = `/tarification/subdivision/delete/${subdivisionId}/`;
    return await AxiosInstanceDelete(url);
}

export async function deleteWorker(workerId) {
    const url = `/tarification/workers/delete/${workerId}/`;
    return await AxiosInstanceDelete(url);
}
export async function deleteHiringPosition(hiringPositionId) {
    const url = `/tarification/hirring-position/delete/${hiringPositionId}/`;
    return await AxiosInstanceDelete(url);
}
export async function deleteHiringPositionByHour(hiringPositionId) {
    const url = `/tarification/hirring-position-byhour/delete/${hiringPositionId}/`;
    return await AxiosInstanceDelete(url);
}

export async function getTarifPosition() {
    return await AxiosInstanceGet(`/tarification/position/`)
}

export async function getTypeofCharging() {
    return await AxiosInstanceGet(`/tarification/type-charging/`)
}
export async function getEmployeeCategories() {
    return await AxiosInstanceGet(`/tarification/employee-categories/`)
}

export async function getTarifReport(queryString) {
    return await AxiosInstanceGet(`/tarification/excel-report/?${queryString}`)
}
export async function getTarifReportByHour(queryString) {
    return await AxiosInstanceGet(`/tarification/excel-report-by-hour/?${queryString}`)
}

export async function getPlanReceipts(contractorId) {
    return await AxiosInstanceGet(`/budget-funding/plan-reciepts/?contractor_id=${contractorId}`)
}

export async function getPlanReceiptsDetails(id) {
    return await AxiosInstanceGet(`/budget-funding/plan-reciepts/${id}/`)
}

export async function getPlanExpenses(contractorId) {
    return await AxiosInstanceGet(`/budget-funding/plan-expenses/?contractor_id=${contractorId}`)
}
export async function getPlanExpensesDetails(id) {
    return await AxiosInstanceGet(`/budget-funding/plan-expenses/${id}/`)
}

export async function getBudgetRevenueClassifier(id) {
    if (id !==''){
        return await AxiosInstanceGet(`/budget-funding/budget-revenue-classifier/?id=${id}`)
    }
    else{
        return await AxiosInstanceGet(`/budget-funding/budget-revenue-classifier/`)
    }
}

export async function createPlanReciepts(data) {
    return await AxiosInstancePost("/budget-funding/plan-reciepts/create/", data)
}
