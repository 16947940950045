import React, {useState, useEffect, useContext} from "react"
import { getFooterMenus } from "../../lib/call-api"
import { MT_COMPANY, MT_SERVICE, MT_OTHER } from "../../lib/constants"
import { Link } from "gatsby"
import SiteContext from "../../context/site-context";

export default function Footer() {
  const [menu, setMenu] = useState(() => [])
  const { loadingState } = useContext(SiteContext)

  useEffect(() => {
    if (loadingState.menuItemsLoaded === true) {
      getFooterMenus()
         .then(response => {
           if (response !== undefined && response.status === 200) {
             setMenu(response.data)
           }
         })
         .catch(error => console.error(error))
    }
  }, [loadingState.menuItemsLoaded])

  return (
    <div>
      <div className="footer-wrapper">
        <div className="inner">
          <div className="container">
            <div className="push35"/>

            <div className="footer-top relative">
              <div className="row">
                <div className="col-lg-4">
                  <div className="footer-logo">
                    <Link to="/">
                      <img
                        src="/images/site/footer-logo.png"
                        width="120px"
                        alt="e-sandyq.kz - портал информационно-технической поддержки"
                      />
                    </Link>
                  </div>
                  <div className="push20"/>

                  <div className="copyright f13">
                    <div className="push8"/>
                    БИН 180340034595; ИИК KZ26998UTB0000446654 ; БИК TSESKZKA{" "}
                    <br />
                    E-sandyq.kz - Портал информационно-технической поддержки
                  </div>
                  {/* {/* <br /> */}
                  {/* <div className="social-links f16 ms-3">
                  <a href="/" className="vk_link">
                    <i className="fa fa-vk" aria-hidden="true"/>
                  </a>
                  <a href="/" className="fb_link">
                    <i className="fa fa-facebook" aria-hidden="true"/>
                  </a>
                  <a href="/" className="tw_link">
                    <i className="fa fa-twitter" aria-hidden="true"/>
                  </a>
                  <a href="/" className="inst_link">
                    <i className="fa fa-instagram" aria-hidden="true"/>
                  </a>
                  <a href="/" className="yutb_link">
                    <i className="fa fa-youtube-play" aria-hidden="true"/>
                  </a> 
                </div> */}
                  <div className="hidden-lg">
                    <div className="push12"/>
                    <hr style={{ opacity: 0.2 }} />
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="push8"/>
                  <div className="row">
                    <div className="col-sm-6 col-md-5">
                      <div className="footer-title">Контактные данные</div>
                      <div className="footer-contacts f13">
                        <div className="element relative">
                          <i className="fa fa-map-marker"/>
                          <span>Кызылординская область, город Кызылорда, Микрорайон Саулет, улица Кудайберген Султанбаев, дом 17</span>
                        </div>

                        <div className="element relative">
                          <i className="fa fa-phone"/>
                          <span>
                            <a href="tel:87783155223">8 (778) 315-52-23</a>
                          </span>
                        </div>

                        <div className="element relative">
                          <i className="fa fa-clock-o"/>
                          <span>Режим работы: с 9.00 - 18.30 Пн-Сб</span>
                        </div>

                        {/* <div className="element el4 relative">
                          <i className="fa fa-envelope"/>
                          <a href="mailto:info@qfin.kz">info@qfin.kz</a>
                        </div> */}
                      </div>
                      <div className="hidden-sm hidden-md hidden-lg">
                        <div className="push12"/>
                        <hr style={{ opacity: 0.2 }} />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-md-offset-1">
                      <div className="footer-title">Клиентам компании</div>

                      <nav className="footer-menu">
                        <ul className="">
                          {menu.map((object, index) => {
                            if (object.menu.menuType === MT_COMPANY) {
                              var href =
                                "/infopages/company/" + object.menu.slug
                            } else if (object.menu.menuType === MT_SERVICE) {
                              href = "/infopages/service/" + object.menu.slug
                            } else if (object.menu.menuType === MT_OTHER) {
                              href = "/infopages/other/" + object.menu.slug
                            } else {
                              href = "/" + object.slug
                            }

                            return (
                              <li
                                key={object.id}
                                className={
                                  index === 0
                                    ? "first"
                                    : index === menu.length - 1
                                    ? "last"
                                    : ""
                                }
                              >
                                <Link to={href}>{object.menu.title}</Link>
                              </li>
                            )
                          })}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="push30"/>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="push15 visible-xs"/>
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                © {new Date().getFullYear()}. e-sandyq.kz - Портал информационно-технической
                поддержки
              </div>
              {/* <div className="col-sm-6">
                
                <div className="push15 visible-xs"/>
              </div> */}
              <div className="col-sm-6 text-right-sm">
                <div className="push5"/>
                {/* <div className="development">
                  разработано в{" "}
                  <Link to="https://qfin.kz">
                    qfin.kz
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
          <div className="push20 visible-xs"/>
        </div>
      </div>
      <span id="up">
        <i className="fa fa-angle-up"/>
      </span>

      {/*            <div className="modal" id="callback">
                <div className="rf">
                    <form method="post" className="ajax_form" onSubmit={() => {}}>
                        <div className="title-h3">авторизация</div>
                        <div className="form-group">
                            <input name="email" type="email" className="form-control required"
                                   placeholder="адрес почты *" value=""/>
                        </div>
                        <div className="form-group">
                            <input name="email" type="password" className="form-control required tel"
                                   placeholder="пароль *" value=""/>
                        </div>
                        <p><small><span className="red f16">*</span> - поля, обязательные для заполнения</small></p>
                        <input name="callbackbtn" type="submit" className="btn" value="вход"/>

                        <input type="hidden" name="af_action" value="ff756cf3c06ee3c4c2afb0bb77a9ce29"/>
                    </form>
                </div>
            </div>


            <span id="up"><i className="fa fa-angle-up"></i></span>


            <div className="modal" id="callback">
                <div className="rf">
                    <form method="post" className="ajax_form" onSubmit={() => {}}>
                        <div className="title-h3">авторизация</div>
                        <div className="form-group">
                            <input name="email" type="email" className="form-control required"
                                   placeholder="адрес почты *" value=""/>
                        </div>
                        <div className="form-group">
                            <input name="email" type="password" className="form-control required tel"
                                   placeholder="пароль *" value=""/>
                        </div>
                        <p><small><span className="red f16">*</span> - поля, обязательные для заполнения</small></p>
                        <input name="callbackbtn" type="submit" className="btn" value="вход"/>

                        <input type="hidden" name="af_action" value="ff756cf3c06ee3c4c2afb0bb77a9ce29"/>
                    </form>
                </div>
            </div>*/}
    </div>
  )
}
