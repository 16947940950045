import React from "react"
import MenuLink from "../helpers/menu-link"
import SiteContext from "../../context/site-context"
import { AiFillHome } from "react-icons/ai"
// import { navigate } from "gatsby-link"
import { navigate } from "gatsby"
import { Link } from "gatsby"
import "./nav-bar.css"

export default function NavBar() {
  const { menuItemsState, loadingState } = React.useContext(SiteContext)

  const [menuLocState, menuLocSetState] = React.useState(() => {
    return {
      topCompanyItems: [],
      childrenCompanyItems: [],
      topServiceItems: [],
      childrenServiceItems: [],
      topToolsItems: [],
      childrenToolsItems: [],
      topOtherItems: [],
      childrenOtherItems: [],
    }
  })

  const [query, setQuery] = React.useState(() => "")

  React.useEffect(() => {
    const topCompanyItems = menuItemsState.companies.filter(
      item => item.parent_id === null
    )
    const childrenCompanyItems = menuItemsState.companies.filter(
      item => item.parent_id !== null
    )

    const topServiceItems = menuItemsState.services.filter(
      item => item.parent_id === null
    )
    const childrenServiceItems = menuItemsState.services.filter(
      item => item.parent_id !== null
    )

    const topToolsItems = menuItemsState.tools.filter(
      item => item.parent_id === null
    )
    const childrenToolsItems = menuItemsState.tools.filter(
      item => item.parent_id !== null
    )

    const topOtherItems = menuItemsState.others.filter(
      item => item.parent_id === null
    )
    const childrenOtherItems = menuItemsState.others.filter(
      item => item.parent_id !== null
    )

    menuLocSetState({
      topCompanyItems,
      childrenCompanyItems,
      topServiceItems,
      childrenServiceItems,
      topToolsItems,
      childrenToolsItems,
      topOtherItems,
      childrenOtherItems,
    })
  }, [
    menuItemsState.loadedTime,
    menuItemsState.companies,
    menuItemsState.services,
    menuItemsState.others,
    menuItemsState.tools
  ])

  function handleSearchChange(event) {
    const value = event.target.value
    setQuery(value)
  }

  function handleSearchSubmit(event) {
    event.preventDefault()

    navigate("/search/" + query)
    setQuery("")
  }

  return (
    <>
      <div className="top-bar-wrapper visible-md visible-lg header-contacts">
        <div className="inner">
            <div className="top-bar relative">
              <div className="top-menu">
                <nav>
                  <ul className="navbar-list-items">
                    {/* <li className="first">
                      <Link to="/">
                        <AiFillHome className="home-icon" />
                      </Link>
                    </li> */}

                    {/*КОМПАНИЯ*/}
                    {menuLocState.topCompanyItems.length > 0 && (
                      <MenuLink
                        href={
                          "/infopages/company/" +
                          menuLocState.topCompanyItems[0].slug
                        }
                        label="Компания"
                        hasChildren={menuLocState.topCompanyItems.length > 0}
                      >
                        {menuLocState.topCompanyItems.map((item, index) => (
                          <MenuLink
                            key={item.id}
                            href={"/infopages/company/" + item.slug}
                            label={item.title}
                            className={index === 0 ? "first" : ""}
                            hasChildren={
                              menuLocState.childrenCompanyItems.filter(
                                child => child.parent_id === item.id
                              ).length > 0
                            }
                          >
                            {menuLocState.childrenCompanyItems
                              .filter(child => child.parent_id === item.id)
                              .map((child, childIndex) => (
                                <MenuLink
                                  key={child.id}
                                  href={"/infopages/company/" + child.slug}
                                  label={child.title}
                                  className={childIndex === 0 ? "first" : ""}
                                />
                              ))}
                          </MenuLink>
                        ))}
                        <MenuLink
                          key={10000}
                          href="/spec-pages/partners"
                          label="Партнеры"
                        />
                      </MenuLink>
                    )}

                    {/*УСЛУГИ*/}
                    {menuLocState.topServiceItems.length > 0 && (
                      <MenuLink
                        href={
                          "/infopages/service/" +
                          menuLocState.topServiceItems[0].slug
                        }
                        label="Услуги"
                        hasChildren={menuLocState.topServiceItems.length > 0}
                      >
                        {menuLocState.topServiceItems.map((item, index) => (
                          <MenuLink
                            key={item.id}
                            href={"/infopages/service/" + item.slug}
                            label={item.title}
                            className={index === 0 ? "first" : ""}
                            hasChildren={
                              menuLocState.childrenServiceItems.filter(
                                child => child.parent_id === item.id
                              ).length > 0
                            }
                          >
                            {menuLocState.childrenServiceItems
                              .filter(child => child.parent_id === item.id)
                              .map((child, childIndex) => (
                                <MenuLink
                                  key={child.id}
                                  href={"/infopages/service/" + child.slug}
                                  label={child.title}
                                  className={childIndex === 0 ? "first" : ""}
                                />
                              ))}
                          </MenuLink>
                        ))}
                      </MenuLink>
                    )}

                    {/*ИНСТРУМЕНТЫ*/}
                    {menuLocState.topToolsItems.length > 0 && (
                      <MenuLink
                        href={
                          "/tools/" +
                          menuLocState.topToolsItems[0].slug
                        }
                        label="Инструменты"
                        hasChildren={menuLocState.topToolsItems.length > 0}
                      >
                        {menuLocState.topToolsItems.map((item, index) => (
                          <MenuLink
                            key={item.id}
                            href={"/tools/" + item.slug}
                            label={item.title}
                            className={index === 0 ? "first" : ""}
                            hasChildren={
                              menuLocState.childrenToolsItems.filter(
                                child => child.parent_id === item.id
                              ).length > 0
                            }
                          >
                            {menuLocState.childrenToolsItems
                              .filter(child => child.parent_id === item.id)
                              .map((child, childIndex) => (
                                <MenuLink
                                  key={child.id}
                                  href={"/tools/" + child.slug}
                                  label={child.title}
                                  className={childIndex === 0 ? "first" : ""}
                                />
                              ))}
                          </MenuLink>
                        ))}
                      </MenuLink>
                    )}

                    {loadingState.menuItemsLoaded === true &&
                    (<>
                      <MenuLink href={"/news/1"} label={"Новости"} />
                      <MenuLink href={"/cabinet/services/methodical-services/webinars"} label={"Методические услуги"} />
                      <MenuLink href={"/reviews"} label={"Отзывы"} />
                    </>)}

                    <MenuLink href={"/contacts"} label={"Контакты"} />

                    {/*ПРОЧЕЕ*/}
                    {/* <MenuLink
                      href={"/"}
                      label={"..."}
                      className="last down"
                      linkClass={"dropdown-link"}
                      hasChildren={menuLocState.topOtherItems.length > 0}
                      onClick={e => {
                        e.preventDefault()
                      }}
                    >
                      {menuLocState.topOtherItems.map(item => (
                        <MenuLink
                          key={item.id}
                          href={"/infopages/other/" + item.slug}
                          label={item.title}
                          className="down"
                          hasChildren={
                            menuLocState.childrenOtherItems.length > 0
                          }
                        >
                          {menuLocState.childrenOtherItems
                            .filter(child => child.parent_id === item.id)
                            .map((child, childIndex) => (
                              <MenuLink
                                key={child.id}
                                href={"/infopages/other/" + child.slug}
                                label={child.title}
                                className={childIndex === 0 ? "first" : ""}
                              />
                            ))}
                        </MenuLink>
                      ))}
                      <MenuLink
                        href={"/files"}
                        label={"документы для скачивания"}
                      />
                    </MenuLink> */}
                  </ul>
                </nav>
              </div>

              {/* <div className="search-block">
                <form onSubmit={handleSearchSubmit}>
                  <div className="form-group relative">
                    <input
                      type="text"
                      name="query"
                      className="queryfield"
                      placeholder="поиск по сайту"
                      autoComplete="off"
                      onChange={handleSearchChange}
                      value={query}
                      required
                    />
                    <button type="submit" className="searchsbmfl">
                      <i className="material-icons">search</i>
                    </button>
                  </div>
                </form>
              </div> */}
            </div>
          </div>
        </div>

      <div className="top-bar-wrapper-push"/>
    </>
  )
}
