import React, { useContext, useState, useRef, useEffect } from "react"
import axiosInstance from "../../lib/axiosApi"
import SiteContext from "../../context/site-context"
import "./login.css"
// import { navigate } from "gatsby-link"
import { navigate } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha"
import { BiErrorCircle } from "react-icons/bi"
import {SetLocalStorageItem, SetSessionStorageItem} from "../../lib/site-functions";
import {HOST} from "../../lib/constants";

export default function Login() {
  const pathName = typeof window !== "undefined" ? window.location.pathname : ""
  const {
    // userState,
    userSetState,
    showLoginState,
    showLoginSetState,
    loginRedirect,
    setLoginRedirect,
    selectedItemTariff,
    setSelectedItemTariff,
  } = useContext(SiteContext)

  const wrapperRef = useRef(null)
  const usernameRef = useRef(null)
  const captchaRef = useRef(null)

  const [state, setState] = useState(() => {
    return { username: "", password: "", email: "", remember: false }
  })

  const [wrongCredentials, setWrongCredentials] = useState(() => false)

  const [wrongEmail, setWrongEmail] = useState(() => false)

  const [pswReset, setPswReset] = useState(() => false)

  function runCaptcha(event) {
    event.preventDefault()

    if (HOST !== "http://127.0.0.1:8000/") {
      captchaRef.current.execute()
    }
    else {
      handleLoginRequestSubmit(true, "/cabinet/")
    }
  }

  async function handleSubmit(redirect = true, path = "/cabinet/") {
    if (pswReset) {
      await handlePasswordResetSubmit()
    } else {
      await handleLoginRequestSubmit(redirect, path)
    }
  }

  async function handlePasswordResetSubmit() {
    try {
      const response = await axiosInstance.post("/password_reset/", {
        email: state.email,
      })
      if (response.data.status === "OK") {
        setState(prevValues => {
          return { ...prevValues, email: "" }
        })
        alert("Письмо было отправлено на Вашу почту")
        showLoginSetState(false)
      }
    } catch (error) {
      console.error(error)
      if (
        error.response?.data?.email?.[0] ===
        "There is no active user associated with this e-mail address or the password can not be changed"
      ) {
        setWrongEmail(true)
      }
    } finally {
      if (HOST !== "http://127.0.0.1:8000/")
        resetCaptcha()
    }
  }

  async function handleLoginRequestSubmit(redirect, path) {
    if (redirect === undefined || redirect === null) {
      redirect = true
    }

    if (!path) {
      path = "/cabinet/"
    }

    try {
      const response = await axiosInstance.post("/token/obtain/", {
        username: state.username,
        password: state.password,
      })
      axiosInstance.defaults.headers["Authorization"] =
        "JWT " + response.data.access
      if (state.remember) {
        SetLocalStorageItem("access_token", response.data.access)
        SetLocalStorageItem("refresh_token", response.data.refresh)
        SetLocalStorageItem("username", state.username)
        SetLocalStorageItem("iin", response.data.iin)
      } else {
        SetSessionStorageItem("access_token", response.data.access)
        SetSessionStorageItem("refresh_token", response.data.refresh)
        SetSessionStorageItem("username", state.username)
        SetSessionStorageItem("iin", response.data.iin)
      }

      userSetState(prev => {
        return { ...prev, username: state.username, iin: state.iin }
      })
      setState({ username: "", password: "", email: "", remember: false })

      showLoginSetState(false)

      obtainUserDetails().then(tariffId => {
        if (selectedItemTariff.some(({ id }) => tariffId.indexOf(id) >= 0)) {
          setSelectedItemTariff([])
          navigate(path)
        } else if (redirect && !pathName.includes("/cabinet")) {
          navigate(path)
        }
      })
    } catch (error) {
      console.error(error)
      if (
        error.response?.data?.detail ===
        "No active account found with the given credentials"
      ) {
        setWrongCredentials(true)
      }
    } finally {
      setLoginRedirect({ redirect: true, path: "" })
      if (HOST !== "http://127.0.0.1:8000/")
        resetCaptcha()
    }
  }

  async function obtainUserDetails() {
    const userDetails = await axiosInstance.get("/user-detail/")
    //console.log("Login userDetails", userDetails)
    let fullName =
      userDetails.data.first_name + " " + userDetails.data.last_name
    let tariffId = userDetails.data.tarifs
      ?.map(({ id }) => id)
      .filter((value, index, self) => {
        return self.indexOf(value) === index
      })

    const photo = userDetails.data.photo
    const isSuperuser = userDetails.data.is_superuser
    const iin = userDetails.data.iin

    fullName.length > 2
      ? state.remember
        ? SetLocalStorageItem("fullName", fullName)
        : SetSessionStorageItem("fullName", fullName)
      : (fullName = null)

    tariffId
      ? state.remember
        ? SetLocalStorageItem("tariffId", tariffId)
        : SetSessionStorageItem("tariffId", tariffId)
      : (tariffId = null)

    if (photo) {
      state.remember
        ? SetLocalStorageItem("photo", photo)
        : SetSessionStorageItem("photo", photo)
    }

    if (isSuperuser) {
      state.remember
        ? SetLocalStorageItem("isSuperuser", isSuperuser)
        : SetSessionStorageItem("isSuperuser", isSuperuser)
    }

    if (iin) {
      state.remember
        ? SetLocalStorageItem("iin", iin)
        : SetSessionStorageItem("iin", iin)
    }

    userSetState(prev => {
      return {
        ...prev,
        fullName: fullName,
        tariffId: tariffId,
        id: userDetails.data.id,
        photo: photo,
        isSuperuser: isSuperuser,
        iin: iin
      }
    })

    return tariffId
  }

  function resetCaptcha() {
    try{
      setTimeout(() => captchaRef.current.reset(), 1000)
    }
    catch (error) {
      console.error('ON resetCaptcha', error)
    }
  }

  function onInputChange(event) {
    const { name, value } = event.target
    if (name === "remember") {
      setState(prev => {
        return { ...prev, [name]: !prev.remember }
      })
    } else {
      setState(prev => {
        return { ...prev, [name]: value }
      })
    }
  }

  function cancelForm() {
    setState({ username: "", password: "", email: "", remember: false })
    showLoginSetState(false)
    setSelectedItemTariff([])
    setLoginRedirect(prevValues => {
      return { ...prevValues, redirect: true }
    })
  }

  function redirectToRegister() {
    showLoginSetState(false)
    navigate("/registration")
  }

  useOutsideClick(wrapperRef, cancelForm)

  useEffect(() => {
    showLoginState && usernameRef.current.focus()
    setWrongCredentials(false)
    setWrongEmail(false)
    setPswReset(false)
  }, [showLoginState])

  return (
    <div
      className="login-modal"
      style={showLoginState ? { display: "block" } : null}
    >
      <form
        ref={wrapperRef}
        onSubmit={runCaptcha}
        className="login-modal-content animate"
      >
        <div className="imgcontainer">
          <span onClick={cancelForm} className="close" title="Close Modal" onKeyDown={() => {}} aria-hidden="true">
            &times;
          </span>
        </div>
        {pswReset ? (
          <div className="formcontainer">
            <label htmlFor="email">
              <b>Почта</b>
            </label>
            <input
              type="email"
              placeholder="example@mail.com"
              name="email"
              onChange={onInputChange}
              value={state.email}
              style={{ borderColor: wrongEmail && "red" }}
              required
            />

            {wrongEmail && (
              <span className="wrong-credentails">
                <BiErrorCircle /> Почта указана неверно
              </span>
            )}

            <button className="btn" type="submit">
              Восстановить
            </button>
          </div>
        ) : (
          <div className="formcontainer">
            <label htmlFor="username">
              <b>Пользователь</b>
            </label>
            <input
              ref={usernameRef}
              type="text"
              placeholder="Пользователь *"
              name="username"
              onChange={onInputChange}
              value={state.username}
              style={{ borderColor: wrongCredentials && "red" }}
              required
            />

            <label htmlFor="password">
              <b>Пароль</b>
            </label>
            <input
              type="password"
              placeholder="Пароль *"
              name="password"
              onChange={onInputChange}
              value={state.password}
              style={{ borderColor: wrongCredentials && "red" }}
              required
            />
            {wrongCredentials && (
              <span className="wrong-credentails">
                <BiErrorCircle /> Имя пользователя или пароль указан неверно
              </span>
            )}
            <p>
              <small>
                <span className="red f16">*</span> - поля, обязательные для
                заполнения
              </small>
            </p>

            <button className="btn" type="submit">
              Вход
            </button>
            <div className="remember-checkbox">
              <label>
                <input
                  type="checkbox"
                  onChange={onInputChange}
                  checked={state.remember}
                  name="remember"
                  value={state.remember}
                />{" "}
                Запомнить меня
              </label>
            </div>
            <p className="psw">
              Забыли <a onClick={() => setPswReset(true)}
                        >пароль или имя</a>?
            </p>
          </div>
        )}

        <div className="formcontainer" style={{borderRadius:'20px' }}>
          <button type="button" onClick={cancelForm} className="btn cancelbtn">
            Отмена
          </button>
          <button
            type="button"
            onClick={redirectToRegister}
            className="btn registration"
          >
            Создать аккаунт
          </button>
          { HOST !== "http://127.0.0.1:8000/" &&
          <ReCAPTCHA
              ref={captchaRef}
              size="invisible"
              sitekey="6LcS5m4pAAAAAODfV2djhTWdxype30_YugN4J8HL"
              onChange={() =>
                  handleSubmit(loginRedirect.redirect, loginRedirect.path)
              }
              onErrored={error => {
                console.log(error)
                resetCaptcha()
              }}
          />
          }

        </div>
      </form>
    </div>
  )
}

function useOutsideClick(ref, cancelForm) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cancelForm()
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])
}
