import React from "react"
import MobileMenu from "./mobile-menu"
import Header from "./header"
import NavBar from "./nav-bar"
import Footer from "./footer"
import Loader from "../helpers/loader"
import SiteContext from "../../context/site-context"
import {AxiosInstanceGet, getMenus} from "../../lib/call-api"
import {MT_COMPANY, MT_OTHER, MT_SERVICE, MT_TOOLS} from "../../lib/constants"
import Login from "../users/login"
import Scroller from "./scroller"
import "./layout.css"

export default function Layout({children}) {
    const {
        loadingSetState,
        userState,
        userSetState,
        menuItemsState,
        menuItemsSetState,
        showLoginState,
        showLoginSetState,
        showLoader,
        setShowLoader,
    } = React.useContext(SiteContext)

    async function loadMenuItems(delay = 500) {
        let response = null

        try {
            response = await getMenus()
        } catch (err) {
            console.error(err)
        }

        if (
            response !== undefined &&
            response !== null &&
            response.status === 200 &&
            response.data
        ) {
            const companies = response.data.filter(
                item => item.menuType === MT_COMPANY
            )
            const services = response.data.filter(
                item => item.menuType === MT_SERVICE
            )
            const tools = response.data.filter(
                item => item.menuType === MT_TOOLS
            )
            const others = response.data.filter(
                item => item.menuType === MT_OTHER
            )

            menuItemsSetState({
                loadedTime: Date.now(),
                companies,
                services,
                tools,
                others,
            })
            loadingSetState(prevValues => (
                {...prevValues, menuItemsLoaded: true}
            ))

            setShowLoader(false)
        }
        else {

            delay *= 2
            console.log("DELAY", delay)
            setTimeout(loadMenuItems, delay, delay)
        }
    }

    React.useEffect(() => {
        if (userState.username === "") {
            const username =
                localStorage.getItem("username") || sessionStorage.getItem("username")
            const fullName =
                localStorage.getItem("fullName") || sessionStorage.getItem("fullName")
            const tariffId =
                localStorage.getItem("tariffId") || sessionStorage.getItem("tariffId")
            const photo =
                localStorage.getItem("photo") || sessionStorage.getItem("photo")
            const isSuperuser =
                localStorage.getItem("isSuperuser") ||
                sessionStorage.getItem("isSuperuser")

            const tariffIdArray =
                tariffId && tariffId.split(",").map(id => parseInt(id))

            if (username !== undefined) {
                userSetState({
                    username,
                    fullName,
                    tariffId: tariffIdArray,
                    photo,
                    isSuperuser: isSuperuser ? isSuperuser : false,
                })
            }
        }

        if (menuItemsState.loadedTime === null)
            loadMenuItems()
    }, [])

    return (
        <>
            <Login
                showLoginState={showLoginState}
                showLoginSetState={showLoginSetState}
            />
            <Loader show={showLoader}/>

            <Header/>
            <MobileMenu/>
            {/* <NavBar/> */}

            <main className="main-content">{children}</main>

            <Scroller/>
            <Footer/>
        </>
    )
}
