import React from "react"
import { Link } from "gatsby"
import { globalHistory } from "@reach/router"

export default function MenuLink({
  href,
  label,
  btnName,
  onBtnClick,
  state,
  linkClass,
  onLinkClick,
  children,
  hasChildren,
}) {
  const path = globalHistory.location.pathname
  const clicked = state !== undefined && btnName !== undefined ? state[btnName] : false
  return (
    <li className={path === href ? "active" : ""}>
      <Link to={href} onClick={onLinkClick} className={linkClass}>
        <span>{label}</span>
      </Link>
      {hasChildren && (
        <span
          name={btnName}
          onClick={onBtnClick} onKeyDown={() => {}} aria-hidden="true"
          className={"dropdown-button" + (clicked ? " active" : "")}
        />
      )}
      {hasChildren && (
        <ul className={clicked ? "open" : ""}>{children}</ul>
      )}
    </li>
  )
}
