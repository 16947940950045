import {navigate} from "gatsby"

export default async function logout(axiosInstance, userSetState, loggedOutSetState) {
    const pathName = window !== undefined && window.location ? window.location.pathname : null
    try {
        await axiosInstance.post('/blacklist/', {
            "refresh_token": localStorage.getItem("refresh_token") || sessionStorage.getItem("refresh_token")
        });
        localStorage.clear()
        sessionStorage.clear()

        axiosInstance.defaults.headers['Authorization'] = null;
        loggedOutSetState(true)
        userSetState({ username: "", email: "" })
        if (pathName !== null && pathName.startsWith("/cabinet")) {
            await navigate("/")
        }
    } catch (e) {
        console.error(e);
    }
}