import axios from "axios"
import {
  GetHeaderAuthorization,
  GetLocalStorageItem,
  GetSessionStorageItem,
  SetLocalStorageItem,
  SetSessionStorageItem,
  RemoveLocalStorageItem,
  RemoveSessionStorageItem,
} from "./site-functions"
import { BASE_URL } from "./constants"

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    Authorization: GetHeaderAuthorization(),
    "Content-Type": "application/json",
    accept: "application/json",
    "Cache-Control": "no-cache"
  },
})

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config

    // Prevent infinite loops
    if (
      error.response?.status === 401 &&
      (originalRequest.url === BASE_URL + "/token/refresh/" ||
        originalRequest.url === "/token/refresh/")
    ) {
      return Promise.reject(error)
    }

    if (
      error.response?.data.code === "token_not_valid" &&
      error.response?.status === 401 &&
      error.response?.statusText === "Unauthorized"
    ) {
      const refreshToken =
        GetLocalStorageItem("refresh_token") ||
        GetSessionStorageItem("refresh_token")

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]))

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000)

        if (tokenParts.exp > now) {
          return await axiosInstance
            .post("/token/refresh/", { refresh: refreshToken })
            .then(response => {
              if (GetLocalStorageItem("refresh_token")) {
                SetLocalStorageItem("access_token", response.data.access)
                SetLocalStorageItem("refresh_token", response.data.refresh)
              } else {
                SetSessionStorageItem("access_token", response.data.access)
                SetSessionStorageItem("refresh_token", response.data.refresh)
              }

              axiosInstance.defaults.headers["Authorization"] =
                "JWT " + response.data.access
              originalRequest.headers["Authorization"] =
                "JWT " + response.data.access

              return axiosInstance(originalRequest)
            })
            .catch(err => {
              if (err.response?.data.detail === "Token is blacklisted") {
                return axiosInstance(originalRequest)
              }
              return console.error(error)
            })
        } else {
          console.log("Refresh token is expired", tokenParts.exp, now)

          RemoveLocalStorageItem("everything")
          RemoveSessionStorageItem("everything")
          window.location.reload()
        }
      } else {
        console.log("Refresh token not available.")

        RemoveLocalStorageItem("everything")
        RemoveSessionStorageItem("everything")
        window.location.reload()
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error)
  }
)

export default axiosInstance
