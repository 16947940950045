import React from "react"
import { Link } from "gatsby"
import SiteContext from "../../context/site-context"
import logout from "../users/logout"
import axiosInstance from "../../lib/axiosApi"
import "./mobile-menu.css"
import MobileMenuLink from "../helpers/mobile-menu-link"
import { BiUser } from "react-icons/bi"
import { navigate } from "gatsby"
// import { navigate } from "gatsby-link"

function MobileMenu() {
  const pathName = typeof window !== "undefined" ? window.location.pathname : ""

  const {
    loadingState,
    userState,
    userSetState,
    showLoginSetState,
    menuItemsState,
    loggedOutSetState,
  } = React.useContext(SiteContext)

  const [menuLocState, menuLocSetState] = React.useState(() => {
    return {
      topCompanyItems: [],
      childrenCompanyItems: [],
      topServiceItems: [],
      childrenServiceItems: [],
      topOtherItems: [],
      childrenOtherItems: [],
    }
  })

  const [query, setQuery] = React.useState(() => "")

  React.useEffect(() => {
    const topCompanyItems = menuItemsState.companies.filter(
      item => item.parent_id === null
    )
    const childrenCompanyItems = menuItemsState.companies.filter(
      item => item.parent_id !== null
    )

    const topServiceItems = menuItemsState.services.filter(
      item => item.parent_id === null
    )
    const childrenServiceItems = menuItemsState.services.filter(
      item => item.parent_id !== null
    )

    const topOtherItems = menuItemsState.others.filter(
      item => item.parent_id === null
    )
    const childrenOtherItems = menuItemsState.others.filter(
      item => item.parent_id !== null
    )

    menuLocSetState({
      topCompanyItems,
      childrenCompanyItems,
      topServiceItems,
      childrenServiceItems,
      topOtherItems,
      childrenOtherItems,
    })

    menuItemsState.companies.forEach(({ id }) => {
      setClick(prevValues => {
        return { ...prevValues, [id]: false }
      })
    })

    menuItemsState.services.forEach(({ id }) => {
      setClick(prevValues => {
        return { ...prevValues, [id]: false }
      })
    })

    menuItemsState.others.forEach(({ id }) => {
      setClick(prevValues => {
        return { ...prevValues, [id]: false }
      })
    })
  }, [menuItemsState.loadedTime, menuItemsState.companies, menuItemsState.others, menuItemsState.services])

  const [click, setClick] = React.useState({
    menu: false,
  })

  const handleClick = () => {
    setClick(prevValues => {
      return { ...prevValues, menu: !click.menu }
    })
  }

  const handleDropdown = e => {
    const name = e.target.getAttribute("name")
    setClick(prevValues => {
      return { ...prevValues, [name]: !click[name] }
    })
  }

  function handleSearchChange(event) {
    const value = event.target.value
    setQuery(value)
  }

  function handleSearchSubmit(event) {
    event.preventDefault()

    navigate("/search/" + query)
    setQuery("")
    handleClick()
  }

  return (
    <div>
      <div
        onClick={handleClick} onKeyDown={() => {}} aria-hidden="true"
        className={
          "menu-button hidden-md hidden-lg" + (click.menu ? " active" : "")
        }
      >
        <span className="icon-menu-burger">
          <span className="icon-menu-burger__line"/>
        </span>
      </div>

      <div className={"mobile-menu" + (click.menu ? " open" : "")} id="mobmenu">
        <div className="search-block">
          <form
            onSubmit={handleSearchSubmit}
            action="rezultatyi-poiska.html"
            method="Get"
          >
            <div className="form-group relative">
              <input
                type="text"
                name="query"
                className="queryField"
                placeholder="Поиск по сайту"
                autoComplete="off"
                onChange={handleSearchChange}
                value={query}
                required
              />
              <button type="submit" className="searchSbmFl">
                <i className="material-icons">search</i>
              </button>
            </div>
          </form>
        </div>

        <nav>
          <ul className="">
            <MobileMenuLink
              href={"/"}
              label={"главная"}
              className={"first"}
              onLinkClick={handleClick}
            />

            {/*КОМПАНИЯ*/}
            {menuLocState.topCompanyItems.length > 0 && (
              <MobileMenuLink
                href={
                  "/infopages/company/" + menuLocState.topCompanyItems[0].slug
                }
                label="Компания"
                btnName="companyDropdown"
                onBtnClick={handleDropdown}
                state={click}
                onLinkClick={handleClick}
                hasChildren={menuLocState.topCompanyItems.length > 0}
              >
                {menuLocState.topCompanyItems.map((item, index) => (
                  <MobileMenuLink
                    key={item.id}
                    href={"/infopages/company/" + item.slug}
                    label={item.title}
                    className={index === 0 ? "first" : ""}
                    onLinkClick={handleClick}
                    onBtnClick={handleDropdown}
                    btnName={item.id}
                    state={click}
                    hasChildren={menuLocState.childrenCompanyItems.length > 0}
                  >
                    {menuLocState.childrenCompanyItems
                      .filter(child => child.parent_id === item.id)
                      .map((child, childIndex) => (
                        <MobileMenuLink
                          key={child.id}
                          href={"/infopages/company/" + child.slug}
                          label={child.title}
                          className={childIndex === 0 ? "first" : ""}
                          onLinkClick={handleClick}
                        />
                      ))}
                  </MobileMenuLink>
                ))}
                <MobileMenuLink
                  key={10000}
                  href="/spec-pages/partners"
                  label="Партнеры"
                  onLinkClick={handleClick}
                />
              </MobileMenuLink>
            )}
            {/*УСЛУГИ*/}
            {menuLocState.topServiceItems.length > 0 && (
              <MobileMenuLink
                href={
                  "/infopages/service/" + menuLocState.topServiceItems[0].slug
                }
                label="Услуги"
                btnName="servicesDropdown"
                onBtnClick={handleDropdown}
                state={click}
                onLinkClick={handleClick}
                hasChildren={menuLocState.topServiceItems.length > 0}
              >
                {menuLocState.topServiceItems.map((item, index) => (
                  <MobileMenuLink
                    key={item.id}
                    href={"/infopages/service/" + item.slug}
                    label={item.title}
                    className={index === 0 ? "first" : ""}
                    onLinkClick={handleClick}
                    onBtnClick={handleDropdown}
                    btnName={item.id}
                    state={click}
                    hasChildren={menuLocState.childrenServiceItems.length > 0}
                  >
                    {menuLocState.childrenServiceItems
                      .filter(child => child.parent_id === item.id)
                      .map((child, childIndex) => (
                        <MobileMenuLink
                          key={child.id}
                          href={"/infopages/service/" + child.slug}
                          label={child.title}
                          onLinkClick={handleClick}
                          className={childIndex === 0 ? "first" : ""}
                        />
                      ))}
                  </MobileMenuLink>
                ))}
              </MobileMenuLink>
            )}

            {loadingState.menuItemsLoaded === true &&
            (<>
              <li className={pathName === "/news" ? " active" : ""}>
                <Link to="/news" onClick={handleClick}>
                  Новости
                </Link>
              </li>
              <li className={pathName === "/reviews" ? " active" : ""}>
                <Link to="/reviews" onClick={handleClick}>
                  Отзывы
                </Link>
              </li>
            </>)}

            <li className={pathName === "/contacts" ? " active" : ""}>
              <Link to="/contacts" onClick={handleClick}>
                Контакты
              </Link>
            </li>

            {/*ПРОЧЕЕ*/}
            <MobileMenuLink
              href={"/"}
              label="Прочее"
              className="last down"
              onLinkClick={handleClick}
              onBtnClick={handleDropdown}
              btnName="others"
              state={click}
              hasChildren={menuLocState.topOtherItems.length > 0}
            >
              {menuLocState.topOtherItems.map(item => (
                <MobileMenuLink
                  key={item.id}
                  href={"/infopages/other/" + item.slug}
                  label={item.title}
                  className="down"
                  onLinkClick={handleClick}
                  onBtnClick={handleDropdown}
                  btnName={item.id}
                  state={click}
                  hasChildren={menuLocState.childrenOtherItems.length > 0}
                >
                  {menuLocState.childrenOtherItems
                    .filter(child => child.parent_id === item.id)
                    .map((child, childIndex) => (
                      <MobileMenuLink
                        key={child.id}
                        href={"/infopages/other/" + child.slug}
                        label={child.title}
                        onLinkClick={handleClick}
                        className={childIndex === 0 ? "first" : ""}
                      />
                    ))}
                </MobileMenuLink>
              ))}
              <MobileMenuLink
                href={"/files"}
                label={"документы для скачивания"}
              />
            </MobileMenuLink>
          </ul>
        </nav>

        <div className="push30"/>
        <div className="mobile-menu-info white">

          {loadingState.menuItemsLoaded === true &&
          (<>
            {userState.username === "" || userState.username === null ? (
              <a href='#' onKeyDown={() => {}} aria-hidden="true"
                onClick={e => {
                  e.preventDefault()
                  handleClick()
                  showLoginSetState(true)
                }}
                className="btn color-scheme invert fancyboxModal"
                style={{ cursor: "pointer" }}
              >
                <i className="material-icons">apps</i> Личный кабинет
              </a>
            ) : (
              <div className="mobile-dropdown">
                <a href='#'
                  className="mobile-callback-btn logged-in btn color-scheme invert fancyboxmodal"
                  style={{ cursor: "pointer" }}
                >
                  <BiUser className="user-logo" />
                  <span>
                    {userState.fullName ? userState.fullName : userState.username}
                  </span>
                </a>
                <div className="mobile-dropdown-content">
                  <Link to="/cabinet/" onClick={handleClick}>
                    Личный кабинет
                  </Link>
                  <Link to="/cabinet/profile" onClick={handleClick}>
                    Профиль
                  </Link>
                  <Link to="/cabinet/tariff" onClick={handleClick}>
                    Мой тариф
                  </Link>
                  <Link to="/cabinet/bookmarked-events" onClick={handleClick}>
                    Мои закладки
                  </Link>
                  <Link to="/cabinet/registered-events" onClick={handleClick}>
                    Мои мероприятия
                  </Link>
                  <a href='#' onKeyDown={() => {}} aria-hidden="true"
                    onClick={() => {
                      logout(axiosInstance, userSetState, loggedOutSetState)
                      handleClick()
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Выйти
                  </a>
                </div>
              </div>
            )}
          </>)}

          <div className="push20"></div>

          <div className="mob-contacts">
            <div className="mob-menu-address f12 weight300">
              г. Астана, ул. Петрова 18/2
            </div>
            <div className="push10"></div>

            <div className="mob-menu-tel weight800">
              <a href="tel:87783155223">+7 (705) 956-11-05</a>
            </div>

            <div className="mob-menu-email">
              <a href="mailto:info@qfin.kz">info@qfin.kz</a>
            </div>
          </div>

          <div className="push10"></div>

          <div className="mob-shedule f12 weight300">
            <div className="inner">
              Режим работы:
              <div className="element">с 9.00 - 18.30 без выходных</div>
            </div>
          </div>
          <div className="push30"></div>
        </div>
        <div className="push30"></div>
      </div>
    </div>
  )
}

export default MobileMenu
