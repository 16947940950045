
export const MT_COMPANY = 1;  // menu type Company
export const MT_SERVICE = 2;  // menu type Service
export const MT_TOOLS = 4;  // menu type Tools
export const MT_OTHER = 3;    // menu type Other

export const ITEMS_PER_PAGE = 6; // for paginator component

export const HOST = "https://api.e-sandyq.kz/"
// export const HOST = "http://127.0.0.1:8000/"

export const BASE_URL = HOST + "api/"
